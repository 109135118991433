import React from "react";

import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import Comment from "./comment";
function DetailHolder() {
  return (
    <>
      <section className="py-3 md:py-8  flex divide-x divide-light-Dark md:px-10">
        <div className="w-full xl:w-2/3 xl:pr-10 ]">
          <LeftSide />
        </div>
        <div className="w-1/3 hidden xl:block">
          <RightSide />
        </div>
      </section>
      <Comment />
    </>
  );
}

export default DetailHolder;
