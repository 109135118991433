import React from "react";
import clsx from "clsx";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

interface CustomLayoutProps extends React.ComponentPropsWithRef<"div"> {
  mainClass?: string;
}
function Layout({
  className,
  children,
  mainClass,
  ...props
}: CustomLayoutProps) {
  return (
    <div className={clsx("Layout overflow-hidden", className)} {...props}>
      <header>
        <Navbar />
      </header>
      <span className="hidden md:block content-none h-screen w-[1px] z-10 bg-light-Dark fixed top-0 bottom-0 left-16 " />
      <span className="hidden md:block content-none h-screen w-[1px] z-10 bg-light-Dark fixed top-0 bottom-0 right-16" />
      <span className="hidden md:block content-none h-screen w-[1px] z-30 bg-light-Dark fixed top-0  left-16 h-[66px] semi-mid:h-[86px]" />
      <span className="hidden md:block content-none h-screen w-[1px] z-30 bg-light-Dark fixed top-0  right-16 h-[66px] semi-mid:h-[86px]" />
      <main
        className={clsx(
          "mx-6 sm:mx-10 md:mx-16 mt-[66px] semi-mid:mt-[86px] ",
          mainClass
        )}
      >
        {children}
      </main>
      <footer className="mx-6 sm:mx-10 md:mx-16">
        <Footer />
      </footer>
    </div>
  );
}

export default Layout;
