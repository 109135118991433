import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WorkCard from "../../../components/WorkCard";
function WhyMe() {
  const sliderSettings = {
    dots: true,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    speed: 4000,
    touchMove: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: any) => (
      <div className="ft-slick__dots--custom xl:hidden">
        <div className="loading" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section className="pt-10 pb-2 ">
      <div className="flex items-center justify-between pb-10 ">
        <div>
          <h1 className="uppercase text-base font-semibold font-primary">
            Collaboration
          </h1>
          <h2 className="text-header">Why work with me</h2>
        </div>
      </div>
      <div className="why-section pt-14 pb-9">
        <Slider {...sliderSettings}>
          <WorkCard />
          <WorkCard />
          <WorkCard />
          <WorkCard />
        </Slider>
      </div>
    </section>
  );
}

export default WhyMe;
