import React, { useEffect } from "react";
import Layout from "../../layout";
import Banner from "./Banner";
import DesignProcess from "./DesignProcess";
import TechStack from "./TechStack";
import AboutMe from "./AboutMe";
import Testimonials from "./Testimonials";
import Portfolio from "./Portfolio";
import WhyMe from "./WhyMe";
import Contact from "./Contact";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Banner />
      <div id="case-study">
        <DesignProcess />
      </div>
      <TechStack />
      <div id="about">
        <AboutMe />
      </div>
      <Testimonials />
      <div id="portfolio">
        <Portfolio />
      </div>
      <WhyMe />

      <div id="contact">
        <Contact />
      </div>
    </Layout>
  );
}

export default Home;
