import React from "react";
import { ReactComponent as Star } from "../../../../assets/svg/Star.svg";
import { ReactComponent as Comma } from "../../../../assets/svg/comma.svg";
function LeftSide() {
  return (
    <>
      <div className="text-container mb-10 md:mb-14">
        <h1 className="text-white text-xl mid:text-4xl font-semibold uppercase font-primary">
          Most efficient design process for budding ui ux designers in 2024
        </h1>
        <p className="mt-4">
          Our event photography service is dedicated to capturing the magic of
          your special occasions. Whether it's a wedding, corporate event, or
          milestone celebration, we're there to document every heartfelt moment.
          We blend into the background, ensuring natural and candid shots that
          reflect the emotions of the day. Our event photography service is
          dedicated to capturing the magic of your special occasions. Whether
          it's a wedding, corporate event, or milestone celebration, we're there
          to document every heartfelt moment. We blend into the background,
          ensuring natural and candid shots that reflect the emotions of the
          day. Our event photography service is dedicated to capturing the magic
          of your special occasions. Whether it's a wedding, corporate event, or
          milestone celebration, we're there to document every heartfelt moment.
          We blend into the background, ensuring natural and candid shots that
          reflect the emotions of the day.
        </p>
      </div>
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;ROLES AND RESPONSIBILITIES&nbsp;</span>
        </h1>
        <div className="flex items-start gap-2 md:gap-5 flex-col mt-5 px-5 md:px-14">
          <div className="flex items-center gap-2 md:gap-6">
            <div className="social rounded-full flex items-center gap-1 border border-[2px] border-light-Dark bg-dark p-2">
              <span className="rounded-full flex text-sm text-white w-8 h-8 items-center justify-center font-semibold gap-1 border border-[1px] border-[#2F2F37] bg-light-Dark p-2">
                01
              </span>
            </div>
            <h2 className="text-base">Visual Designer</h2>
          </div>
          <div className="flex items-center gap-2 md:gap-6">
            <div className="social rounded-full flex items-center gap-1 border border-[2px] border-light-Dark bg-dark p-2">
              <span className="rounded-full flex text-sm text-white w-8 h-8 items-center justify-center font-semibold gap-1 border border-[1px] border-[#2F2F37] bg-light-Dark p-2">
                02
              </span>
            </div>
            <h2 className="text-base">User Research</h2>
          </div>
          <div className="flex items-center gap-2 md:gap-6">
            <div className="social rounded-full flex items-center gap-1 border border-[2px] border-light-Dark bg-dark p-2">
              <span className="rounded-full flex text-sm text-white w-8 h-8 items-center justify-center font-semibold gap-1 border border-[1px] border-[#2F2F37] bg-light-Dark p-2">
                03
              </span>
            </div>
            <h2 className="text-base">Prototyping & Testing</h2>
          </div>
        </div>
      </div>
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center  justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;PROBLEM STATEMENT&nbsp;</span>
        </h1>

        <p className="text-white mt-3 px-1">
          Sometimes strangers who have gone through similar situations can give
          the best advice when we’re going through rough times in our lives. How
          can you design an environment where people who are seeking help feel
          safe to connect and support each other?
        </p>
      </div>
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center  justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;OVERVIEW&nbsp;</span>
        </h1>
        <div className="flex items-center gap-4 mt-3 px-1">
          <div className="w-20 h-20 flex-shrink-0 hidden md:block ">
            <Comma className=" w-full h-full    " />
          </div>
          <p className="text-white ">
            THE RAY (the ray of hope) is one of a kind mental health
            communication app that allows the users to interact with various
            communities of people and professional therapists with whom he/she
            can share his/her stories and have a heart-to-heart chat with full
            privacy and security. It provides a platform of communication to
            those who seek mental health support.
          </p>
        </div>
      </div>
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center  justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;UNDERSTANDING THE USER&nbsp;</span>
        </h1>
        <ul className=" mt-3 px-8">
          <li className="list-disc text-white mb-3">
            Gujiya, 22, suffers anxiety and depression post-breakup.
          </li>
          <li className="list-disc text-white mb-3">
            Boyfriend cheated during 2020 lockdown.
          </li>
          <li className="list-disc text-white mb-3">
            Feels unheard, conservative family, few close relationships.
          </li>
          <li className="list-disc text-white mb-3">
            No one to share with, tried coping methods.
          </li>
          <li className="list-disc text-white mb-3">
            Turns to THE RAY, Nepalese gov't mental health app.
          </li>
        </ul>
      </div>
    </>
  );
}

export default LeftSide;
