import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from "../Button";

const navItems = [
  {
    id: 1,
    url: "/#case-study",
    display: "Case Study",
    isInternal: true,
  },
  {
    id: 2,
    url: "/#about",
    display: "About",
    isInternal: true,
  },
  {
    id: 3,
    url: "/#portfolio",
    display: "Portfolio",
    isInternal: true,
  },
  {
    id: 4,
    url: "/blog",
    display: "Blog",
    isInternal: false,
  },
  {
    id: 5,
    url: "/#contact",
    display: "Contact",
    isInternal: true,
  },
];

function Navbar() {
  const [toggle, toggleNav] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (e: any, url: any, isInternal: any) => {
    e.preventDefault();
    if (isInternal) {
      const targetId = url.split("#")[1];
      if (location.pathname === "/") {
        const target = document.getElementById(targetId);
        if (target) {
          window.scrollTo({
            top: target.offsetTop - 66, // Adjust this value based on your navbar height
            behavior: "smooth",
          });
        }
        toggleNav(false); // Close the mobile menu if open
      } else {
        navigate("/", { replace: true });
        setTimeout(() => {
          const target = document.getElementById(targetId);
          if (target) {
            window.scrollTo({
              top: target.offsetTop - 66,
              behavior: "smooth",
            });
          }
          toggleNav(false);
        }, 100); // Delay to allow the navigation to complete
      }
    } else {
      navigate(url);
    }
  };

  return (
    <nav className="nav-container z-30 bg-dark fixed inset-0 h-[66px] semi-mid:h-[86px] border-b-[1px] border-light-Dark px-6 sm:px-10 md:px-20">
      <div className="nav-inner-container flex items-center justify-between h-full">
        <Link to="/">
          <div className="text-2xl z-40 semi-mid:text-xl mid:text-2xl lg:text-3xl text-white text-primary uppercase font-primary flex items-center gap-1">
            Nikita
            <span className="w-1.5 mid:w-2 h-1.5 mid:h-2 rounded bg-purple content-none mt-2 mid:mt-3 lg:mt-4"></span>
          </div>
        </Link>
        <ul className="hidden semi-mid:flex border-t-[1px] border-x-[1px] rounded-t-xl border-light-Dark self-end">
          {navItems.map((nav) => (
            <li
              key={nav.id}
              className="font-primary text-sm semi-mid:py-4 mid:py-4 lg:py-6 semi-mid:px-3 mid:px-6 lg:px-10 text-navColor border-l-[1px] border-light-Dark first:border-l-0"
            >
              <Link
                to={nav.url}
                onClick={(e) => handleNavigation(e, nav.url, nav.isInternal)}
              >
                {nav.display}
              </Link>
            </li>
          ))}
        </ul>

        <Button underLine className="hidden semi-mid:block">
          Hire Me
        </Button>
        <span
          className="block semi-mid:hidden z-40"
          onClick={() => toggleNav(!toggle)}
          aria-label="Toggle navigation"
        >
          <span
            className="line"
            style={{
              width: toggle ? "40%" : "70%",
            }}
          />
          <span className="line" />
          <span
            className="line"
            style={{
              width: toggle ? "55%" : "70%",
            }}
          />
        </span>
      </div>
      <ul
        className="fixed bottom-0 left-0 z-30 right-0 bg-dark top-[66px] semi-mid:top-[86px] semi-mid:hidden"
        style={{
          transform: toggle ? "translateY(0%)" : "translateY(-200%)",
          transition: "all 0.4s ease-in-out",
        }}
      >
        {navItems.map((nav) => (
          <li
            key={nav.id}
            className="text-center py-6 text-2xl mt-3"
            onClick={(e) => handleNavigation(e, nav.url, nav.isInternal)}
          >
            <Link to={nav.url}>{nav.display}</Link>
          </li>
        ))}
        <div className="mt-10 flex items-center justify-center">
          <Button underLine className="!text-2xl !px-8 !py-4">
            Hire Me
          </Button>
        </div>
      </ul>
    </nav>
  );
}

export default Navbar;
