import React from "react";
import Button from "../../../components/Button";
import DesignCard from "../../../components/DesignCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function DesignProcess({ noUnderline }: { noUnderline?: boolean }) {
  const sliderSettings = {
    dots: true,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    speed: 500,
    touchMove: true,
    cssEase: "linear",
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: any) => (
      <div className="ft-slick__dots--custom xl:hidden">
        <div className="loading" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 715,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      className="pt-10 "
      style={{
        borderBottom: noUnderline ? "none" : "1px solid #1c1c21",
      }}
    >
      <div className="flex items-center justify-between">
        <div>
          <h1 className="uppercase text-base font-semibold font-primary">
            Case Study
          </h1>
          <h2 className="text-header">My Design Process</h2>
        </div>
        <Button className="hidden semi-mid:block">View All Projects</Button>
      </div>
      <div className="slider-design-process my-5 py-5 ">
        <Slider {...sliderSettings}>
          <DesignCard />
          <DesignCard />
          <DesignCard />
        </Slider>
      </div>
    </section>
  );
}

export default DesignProcess;
