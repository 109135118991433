import React from "react";
import Nikita from "../../../assets/images/nikita.png";
import { ReactComponent as Star } from "../../../assets/svg/Star.svg";
import Button from "../../../components/Button";

function Banner() {
  return (
    <section className="py-3 semi-mid:py-16 flex items-center justify-center w-full flex-col semi-mid:flex-row">
      <div className="image-holder w-full sm:w-2/3 semi-mid:w-[195px] h-[400px] sm:h-[450px] md:h-[480px] semi-mid:h-[258px] flex-shrink-0 rounded-2xl overflow-hidden">
        <img
          src={Nikita}
          alt="Nikita"
          className="banner-image w-full h-full object-cover object-top"
        />
      </div>
      <div className="semi-mid:pl-6 py-3 flex-grow">
        <h1 className="text-4xl semi-mid:text-[55px] lg:text-[65px] xl:text-[88px] xl:leading-[65px] text-purple w-full font-banner tracking-wide">
          hi there,
        </h1>
        {/* <h2 className="text-[1.15rem] sm:text-3xl semi-mid:text-[2rem] mid:text-[2.2rem] mid:leading-[45px] lg:text-[2.5rem] font-medium text-white uppercase font-primary"> */}
        <h2 className="text-header">
          I am Nikita, a
          <span className="text-purple">&nbsp;product designer&nbsp;</span>
          with an experience of&nbsp;
          <span style={{ display: "inline-flex", alignItems: "baseline" }}>
            <Star className="w-7 h-7 px-1 lg:w-10 lg:h-10 self-center" />
            <span>&nbsp;4+&nbsp;</span>
          </span>
          years.
        </h2>
        <Button underLine className="block semi-mid:hidden mt-5">
          Hire Me
        </Button>
      </div>
    </section>
  );
}

export default Banner;
