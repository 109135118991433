import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { Col, Row } from "antd";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { country } from "../../../constants";
import Button from "../../../components/Button";

export const contactSchema = z.object({
  name: z
    .string({ invalid_type_error: "Name is required" })
    .nonempty({ message: "Name is required" })
    .trim()
    .min(2, { message: "Name should be atleast 2 characters" }),
  email: z
    .string({ invalid_type_error: "Email is required" })
    .nonempty({ message: "Email is required" })
    .trim()
    .email(),
  country: z
    .string({ invalid_type_error: "country is required" })
    .nonempty({ message: "Country is required" })
    .trim(),
  city: z
    .string({ invalid_type_error: "City is required" })
    .nonempty({ message: "City is required" })
    .trim(),
  company: z
    .string({ invalid_type_error: "Company is required" })
    .nonempty({ message: "Company is required" })
    .trim(),
  phone: z
    .string({ invalid_type_error: "Phone Number is required" })
    .nonempty({ message: "Phone Number is required" })
    .regex(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid"
    ),
  message: z
    .string({ invalid_type_error: "Message is required" })
    .nonempty({ message: "Message is required" })
    .min(2, "Name needs to be longer"),
});
type ContactFormData = z.infer<typeof contactSchema>;

function Contact() {
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors, isLoading },
  } = useForm<ContactFormData>({
    resolver: zodResolver(contactSchema),
    mode: "onBlur", // "onChange"
  });

  function ContactTitle() {
    return (
      <section className="py-10">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="uppercase text-base font-semibold font-primary">
              Contact
            </h1>
            <h2 className="text-header text-light">get in touch</h2>
          </div>
        </div>
      </section>
    );
  }

  const onSubmit = async (data: ContactFormData) => {
    console.log(data);
    reset();
  };
  return (
    <>
      <ContactTitle />
      <section className="relative border-none">
        <div className=" side-box box-left" />
        <div className=" side-box box-right" />
        <form
          action=""
          className="bg-[#0E0E10] rounded-xl my-11 p-4 semi-mid:p-8 semi-mid:mx-20 lg:mx-52"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Input
                name="name"
                control={control}
                label="Full Name"
                parentClass=""
                register={register}
                labelClass="text-secondary"
                placeholder="Full Name"
                error={errors}
                icon="fa-solid fa-user"
              />
            </Col>
            <Col xs={24} md={12}>
              <Input
                name="email"
                control={control}
                label="Email"
                type="email"
                parentClass="Email"
                placeholder="Email"
                register={register}
                labelClass="text-secondary"
                error={errors}
                icon="fa-solid fa-envelope"
              />
            </Col>
          </Row>
          <Row gutter={16} className="mt-4 semi-mid:mt-6">
            <Col xs={24} md={12}>
              <Select
                name="country"
                control={control}
                label="Country"
                parentClass=""
                register={register}
                labelClass="text-secondary"
                placeholder="Select Your Country"
                error={errors}
                options={country}
                icon="fa-solid fa-location-dot"
              />
            </Col>
            <Col xs={24} md={12}>
              <Select
                name="city"
                control={control}
                label="City"
                parentClass=""
                register={register}
                labelClass="text-secondary"
                placeholder="Select Your City"
                error={errors}
                options={country}
                icon="fa-solid fa-location-dot"
              />
            </Col>
          </Row>
          <Row gutter={16} className="mt-4 semi-mid:mt-6">
            <Col xs={24} md={12}>
              <Input
                name="company"
                control={control}
                label="Company Name"
                parentClass=""
                register={register}
                labelClass="text-secondary"
                placeholder="Entry Your Country Name"
                error={errors}
                icon="fa-solid fa-hotel"
              />
            </Col>
            <Col xs={24} md={12}>
              <Input
                name="phone"
                control={control}
                label="Phone number"
                parentClass="phone"
                register={register}
                placeholder="Entry Your Phone Number"
                labelClass="text-secondary"
                error={errors}
                icon="fa-solid fa-phone"
                type="number"
              />
            </Col>
          </Row>
          <Row gutter={16} className="mt-4 semi-mid:mt-6">
            <Col xs={24}>
              <Input
                name="message"
                control={control}
                label="Message"
                parentClass="Message"
                register={register}
                placeholder="Entry Your Message"
                field="textarea"
                labelClass="text-secondary"
                error={errors}
                icon="fa-solid fa-pencil"
              />
            </Col>
          </Row>
          <Button
            className="bg-purple border-[1px] border-[#5926AC] hover:bg-[#5926AC] flex items-center justify-center gap-3 mt-2 semi-mid:mt-4"
            type="submit"
          >
            Save
            <i className="fa-solid fa-arrow-right " />
          </Button>
        </form>
      </section>
    </>
  );
}

export default Contact;
