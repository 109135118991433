import clsx from "clsx";
import React from "react";

interface CustomButtonProps extends React.ComponentPropsWithRef<"button"> {
  underLine?: boolean;
  className?: string;
}

function Button({
  className,
  children,
  underLine,
  ...props
}: CustomButtonProps) {
  return (
    <button
      className={clsx("Button", underLine ? "underline" : "", className)}
      {...props}
    >
      {children}
    </button>
  );
}

export default Button;
