import clsx from "clsx";
import React from "react";

interface CustomButtonProps extends React.ComponentPropsWithRef<"button"> {
  className?: string;
}

function CircularBtn({ className, children, ...props }: CustomButtonProps) {
  return (
    <button className={clsx("circular-btn", className)} {...props}>
      {children}
    </button>
  );
}

export default CircularBtn;
