import React from "react";
import CircularBtn from "../../../../components/CircularBtn";
import { ReactComponent as Arrow } from "../../../../assets/svg/arrow.svg";
import Image from "../../../../assets/images/blog.png";
import { useNavigate } from "react-router-dom";
function ImageHolder() {
  const navigate = useNavigate();
  return (
    <section
      className="py-10"
      style={{
        borderBottom: "none",
      }}
    >
      <CircularBtn onClick={() => navigate("/blog")}>
        <Arrow className="arrow-straight" />
      </CircularBtn>
      <div className=" flex font-primary text-base uppercase items-center gap-2 my-9 px-2">
        <span
          className="text-lightPurple cursor-pointer"
          onClick={() => navigate("/")}
        >
          Home
        </span>
        <i className="fa-solid fa-greater-than " />
        <span
          className="text-lightPurple cursor-pointer"
          onClick={() => navigate("/blog")}
        >
          Blog
        </span>
        <i className="fa-solid fa-greater-than" />
        <span className="text-purple font-semibold">Blog Detail</span>
      </div>
      <div className="image-holder-blog w-full rounded-xl overflow-hidden">
        <img
          src={Image}
          alt=""
          className="w-full max-h-[540px] object-cover "
        />
      </div>
    </section>
  );
}

export default ImageHolder;
