import React from "react";
import Image from "../../assets/images/blog.png";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
function BlogCard() {
  const navigate = useNavigate();
  return (
    <div className="blog-card flex flex-col mid:flex-row  border border-2 p-3 mid:p-10 rounded-xl border-light-Dark ">
      <div className="image-container m-h-[350px]  mid:w-1/3 overflow-hidden rounded-2xl">
        <img src={Image} alt="" className="w-full h-full object-cover" />
      </div>
      <div className="text-container mid:w-2/3 mid:pl-12 mt-8 mid:mt-3">
        <h1 className="text-white text-xl mid:text-4xl font-semibold uppercase font-primary">
          Most efficient design process for budding ui ux designers in 2024
        </h1>
        <p className="mt-2">
          Our event photography service is dedicated to capturing the magic of
          your special occasions. Whether it's a wedding, corporate event, or
          milestone celebration, we're there to document every heartfelt moment.
          We blend into the background, ensuring natural and candid shots that
          reflect the emotions of the day.
        </p>

        <div className="mt-7 mb-6 mid:mb-0">
          <Button
            className="bg-purple border-[1px] border-[#5926AC] hover:bg-[#5926AC] flex items-center justify-center gap-3  mid:mt-4"
            type="submit"
            onClick={() => navigate("/blog/134")}
          >
            Read More
            <i className="fa-solid fa-arrow-right " />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
