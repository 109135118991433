import React from "react";
import CircularBtn from "../../../components/CircularBtn";
import { ReactComponent as Arrow } from "../../../assets/svg/arrow.svg";
import BlogCard from "../../../components/BlogCard";
import { useNavigate } from "react-router-dom";
function Banner() {
  const navigate = useNavigate();
  return (
    <section className="py-10">
      <CircularBtn onClick={() => navigate("/")}>
        <Arrow className="arrow-straight" />
      </CircularBtn>
      <div className=" flex font-primary text-base uppercase items-center gap-2 my-9 px-2">
        <span
          className="text-lightPurple cursor-pointer"
          onClick={() => navigate("/")}
        >
          Home
        </span>
        <i className="fa-solid fa-greater-than" />
        <span className="text-purple font-semibold">Blog</span>
      </div>
      <div className="blog-Container flex flex-col gap-8 semi-mid:gap-14">
        <BlogCard />
        <BlogCard />
        <BlogCard />
      </div>
    </section>
  );
}

export default Banner;
