import { Alert, InputProps } from "antd";
import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";
import { useState, useEffect } from "react";

type InputTypes = InputProps & {
  name: string;
  type?: string;
  control: any;
  label?: React.ReactNode;
  error?: any;
  parentClass?: string;
  labelClass?: string;
  field?: "input" | "textarea";
  inputClass?: string;
  placeholder?: string;
  required?: boolean;
  errorClass?: string;
  rules?: any;
  value?: any;
  register: any;
  icon?: string;
};

const Input = ({
  name,
  control,
  label,
  error,
  value,
  register,
  parentClass,
  inputClass,
  labelClass,
  field,
  placeholder,
  errorClass,
  type,
  showCount,
  icon,
  rules = null,
  ...rest
}: InputTypes) => {
  return (
    <div className={clsx("mb-4 relative ", parentClass)}>
      <div className="absolute top-[2.8rem] left-5 opacity-80 cursor-pointer">
        {icon && <i className={clsx(icon, "text-lg")}></i>}
      </div>

      <label>
        <h1
          className={clsx(labelClass, "font-semibold text-base font-primary")}
        >
          {" "}
          {label}
        </h1>
      </label>

      {field === "textarea" ? (
        <textarea
          className={clsx(
            "w-full rounded-lg px-12 py-4 mt-2 border border-[1px] border-light-Dark bg-dark ",
            inputClass
          )}
          id={name}
          placeholder={placeholder}
          {...register(`${name}`)}
          rows={6}
        />
      ) : (
        <input
          className={clsx(
            "w-full rounded-lg px-12 py-4 mt-2 border border-[1px] border-light-Dark bg-dark ",
            inputClass
          )}
          id={name}
          type={type ? type : "text"}
          placeholder={placeholder}
          {...register(`${name}`)}
        />
      )}

      <ErrorMessage
        errors={error}
        name={name}
        render={({ message }) => (
          <Alert
            message={message}
            type="error"
            className={clsx(
              "mt-2 text-center rounded-lg text-purple  border border-[1px] border-purple bg-dark",
              errorClass
            )}
          />
        )}
      />
    </div>
  );
};

export default Input;
