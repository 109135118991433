import React, { useState } from "react";
import Image from "../../assets/images/shadow (1).png";
import CircularBtn from "../CircularBtn";
import { ReactComponent as Arrow } from "../../assets/svg/arrow.svg";
import { useNavigate } from "react-router-dom";
function DesignCard() {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  return (
    <div className="design-card px-3 pb-7 xl:pb-0 ">
      <div className="design-card-image-holder bg-light-Dark py-20 border-[1px] border-[#2F2F37]  px-8 flex items-center justify-center rounded-lg">
        <img
          src={Image}
          alt=""
          className="design-card-image"
          style={{
            filter: "drop-shadow(0px 0px 4px #525252)",
            transform: isHovered ? "scale(1.1)" : "scale(1)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </div>
      <div className="mt-4 px-1">
        <h1 className="uppercase text-2xl font-medium break-words text-light">
          IT Company website
        </h1>
        <p className="text-base text-light leading-[24px] mt-2">
          Our event photography service is dedicated to capturing the magic of
          your special occasions. Whether it's a wedding, corporate event, or
          milestone celebration, we're there to document every heartfelt moment.
          We blend into the background, ensuring natural and candid shots that
          reflect the emotions.
        </p>
      </div>
      <div className="flex items-center justify-between mt-5">
        <h2 className="text-purple text-sm  font-semibold">
          User Interface <span className="text-white">|</span> User Experience
        </h2>
        <CircularBtn
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={() => navigate("/DetailWork/134")}
        >
          <Arrow className="arrow" />
        </CircularBtn>
      </div>
    </div>
  );
}

export default DesignCard;
