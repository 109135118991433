import React from "react";
// @ts-ignore
import { ReactComponent as Star } from "../../../assets/svg/Star.svg";
// @ts-ignore
import { ReactComponent as Comma } from "../../../assets/svg/comma.svg";
// @ts-ignore
import Image from "../../../assets/images/empathymap.png";
// @ts-ignore
import Image1 from "../../../assets/images/image 5.png";
// @ts-ignore
import Image2 from "../../../assets/images/image 6.png";
// @ts-ignore
import Image3 from "../../../assets/images/image 7.png";
// @ts-ignore
import Image4 from "../../../assets/images/image 8.png";
function Description() {
  return (
    <section className="py-10 md:px-10">
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center  justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;UX Techniques Used&nbsp;</span>
        </h1>
        <div className="flex items-center mt-5 ">
          <h2 className="text-purple text-base nowrap font-semibold flex items-center">
            User Interface <span className="text-white px-1">|</span>
            User Interface <span className="text-white px-1">|</span>
            User Interface <span className="text-white px-1">|</span>
            User Interface <span className="text-white px-1">|</span>
            User Interface <span className="text-white px-1">|</span>
            User Interface
          </h2>
        </div>
      </div>
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;ROLES AND RESPONSIBILITIES&nbsp;</span>
        </h1>
        <div className="flex items-start gap-2 md:gap-5 flex-col mt-5 px-5 md:px-14">
          <div className="flex items-center gap-2 md:gap-6">
            <div className="social rounded-full flex items-center gap-1 border border-[2px] border-light-Dark bg-dark p-2">
              <span className="rounded-full flex text-sm text-white w-8 h-8 items-center justify-center font-semibold gap-1 border border-[1px] border-[#2F2F37] bg-light-Dark p-2">
                01
              </span>
            </div>
            <h2 className="text-base">Visual Designer</h2>
          </div>
          <div className="flex items-center gap-2 md:gap-6">
            <div className="social rounded-full flex items-center gap-1 border border-[2px] border-light-Dark bg-dark p-2">
              <span className="rounded-full flex text-sm text-white w-8 h-8 items-center justify-center font-semibold gap-1 border border-[1px] border-[#2F2F37] bg-light-Dark p-2">
                02
              </span>
            </div>
            <h2 className="text-base">User Research</h2>
          </div>
          <div className="flex items-center gap-2 md:gap-6">
            <div className="social rounded-full flex items-center gap-1 border border-[2px] border-light-Dark bg-dark p-2">
              <span className="rounded-full flex text-sm text-white w-8 h-8 items-center justify-center font-semibold gap-1 border border-[1px] border-[#2F2F37] bg-light-Dark p-2">
                03
              </span>
            </div>
            <h2 className="text-base">Prototyping & Testing</h2>
          </div>
        </div>
      </div>
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center  justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;PROBLEM STATEMENT&nbsp;</span>
        </h1>

        <p className="text-white mt-3 px-1">
          Sometimes strangers who have gone through similar situations can give
          the best advice when we’re going through rough times in our lives. How
          can you design an environment where people who are seeking help feel
          safe to connect and support each other?
        </p>
      </div>
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center  justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;OVERVIEW&nbsp;</span>
        </h1>
        <div className="flex items-center gap-4 mt-3 px-1">
          <div className="w-20 h-20 flex-shrink-0 hidden md:block ">
            <Comma className=" w-full h-full    " />
          </div>
          <p className="text-white ">
            THE RAY (the ray of hope) is one of a kind mental health
            communication app that allows the users to interact with various
            communities of people and professional therapists with whom he/she
            can share his/her stories and have a heart-to-heart chat with full
            privacy and security. It provides a platform of communication to
            those who seek mental health support.
          </p>
        </div>
      </div>
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center  justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;UNDERSTANDING THE USER&nbsp;</span>
        </h1>
        <ul className=" mt-3 px-8">
          <li className="list-disc text-white mb-3">
            Gujiya, 22, suffers anxiety and depression post-breakup.
          </li>
          <li className="list-disc text-white mb-3">
            Boyfriend cheated during 2020 lockdown.
          </li>
          <li className="list-disc text-white mb-3">
            Feels unheard, conservative family, few close relationships.
          </li>
          <li className="list-disc text-white mb-3">
            No one to share with, tried coping methods.
          </li>
          <li className="list-disc text-white mb-3">
            Turns to THE RAY, Nepalese gov't mental health app.
          </li>
        </ul>
      </div>
      <div className="design-card-image-holder my-10 md:my-14 mb-5 bg-light-Dark py-20 border-[1px] border-[#2F2F37]  px-48 flex items-center justify-center rounded-lg">
        <img src={Image} alt="" className="design-card-image" />
      </div>
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center mb-8  justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;USER PERSONAS&nbsp;</span>
        </h1>
        <div className="design-card-image-holder flex items-center justify-between gap-2  mb-5 bg-light-Dark py-8 border-[1px] border-[#2F2F37]  px-8 flex items-center justify-center rounded-lg">
          <img src={Image1} alt="" style={{ width: "calc(33.3333% - 10px)" }} />
          <img src={Image2} alt="" style={{ width: "calc(33.3333% - 10px)" }} />
          <img src={Image3} alt="" style={{ width: "calc(33.3333% - 10px)" }} />
        </div>
      </div>
      <div className="mb-10 md:mb-14">
        <h1 className="flex items-center  justify-start font-primary text-xl md:text-2xl font-medium text-white">
          <Star className=" px-1 w-8 h-8 self-center" />
          <span>&nbsp;USER PERSONAS&nbsp;</span>
        </h1>
        <p className="text-white my-8 ">
          THE RAY (the ray of hope) is one of a kind mental health communication
          app that allows the users to interact with various communities of
          people and professional therapists with whom he/she can share his/her
          stories and have a heart-to-heart chat with full privacy and security.
          It provides a platform of communication to those who seek mental
          health support.
        </p>
        <div className="design-card-image-holder flex items-center justify-between gap-2  mb-5 bg-light-Dark py-8 border-[1px] border-[#2F2F37]  px-8 flex items-center justify-center rounded-lg">
          <img src={Image4} alt="" />
        </div>
      </div>
    </section>
  );
}

export default Description;
