import React, { useEffect } from "react";
import Layout from "../../layout";
import Banner from "./Banner";
import Description from "./Description";
import DesignCard from "../../components/DesignCard";
import DesignProcess from "../Home/DesignProcess";

function DetailWork() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout mainClass={"pb-10"}>
      <Banner />
      <Description />
      <DesignProcess />
    </Layout>
  );
}

export default DetailWork;
