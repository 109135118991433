import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { Col, Row } from "antd";

import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
// import Input from "../../../components/Input";
// import Select from "../../../components/Select";
// import { country } from "../../../constants";
// import Button from "../../../components/Button";

export const contactSchema = z.object({
  name: z
    .string({ invalid_type_error: "Name is required" })
    .nonempty({ message: "Name is required" })
    .trim()
    .min(2, { message: "Name should be atleast 2 characters" }),
  email: z
    .string({ invalid_type_error: "Email is required" })
    .nonempty({ message: "Email is required" })
    .trim()
    .email(),

  comment: z
    .string({ invalid_type_error: "Message is required" })
    .nonempty({ message: "Message is required" })
    .min(2, "Name needs to be longer"),
});
type ContactFormData = z.infer<typeof contactSchema>;

function Comment() {
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors, isLoading },
  } = useForm<ContactFormData>({
    resolver: zodResolver(contactSchema),
    mode: "onBlur", // "onChange"
  });

  function CommentTitle() {
    return (
      <section className="py-10">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="uppercase text-base font-semibold font-primary">
              comment
            </h1>
            <h2 className="text-header text-light">get in touch</h2>
          </div>
        </div>
      </section>
    );
  }

  const onSubmit = async (data: ContactFormData) => {
    console.log(data);
    reset();
  };
  return (
    <>
      <CommentTitle />
      <section className="relative border-none">
        <div className=" side-box box-left" />
        <div className=" side-box box-right" />
        <form
          action=""
          className="bg-[#0E0E10] rounded-xl my-11 p-4 semi-mid:p-8 semi-mid:mx-20 lg:mx-52"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Input
                name="name"
                control={control}
                label="Full Name"
                parentClass=""
                register={register}
                labelClass="text-secondary"
                placeholder="Full Name"
                error={errors}
                icon="fa-solid fa-user"
              />
            </Col>
            <Col xs={24} md={12}>
              <Input
                name="email"
                control={control}
                label="Email"
                type="email"
                parentClass="Email"
                placeholder="Email"
                register={register}
                labelClass="text-secondary"
                error={errors}
                icon="fa-solid fa-envelope"
              />
            </Col>
          </Row>

          <Row gutter={16} className="mt-4 semi-mid:mt-6">
            <Col xs={24}>
              <Input
                name="comment"
                control={control}
                label="Comment"
                parentClass="Comment"
                register={register}
                placeholder="Entry Your Comment"
                field="textarea"
                labelClass="text-secondary"
                error={errors}
                icon="fa-solid fa-pencil"
              />
            </Col>
          </Row>
          <Button
            className="bg-purple border-[1px] border-[#5926AC] hover:bg-[#5926AC] flex items-center justify-center gap-3 mt-2 semi-mid:mt-4"
            type="submit"
          >
            Save
            <i className="fa-solid fa-arrow-right " />
          </Button>
        </form>
      </section>
    </>
  );
}

export default Comment;
