import React from "react";
import Button from "../../../components/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectCard from "../../../components/ProjectCard";
function Portfolio() {
  const sliderSettings = {
    dots: true,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    speed: 500,
    touchMove: true,
    cssEase: "linear",
    appendDots: (dots: any) => <ul>{dots}</ul>,
    customPaging: (i: any) => (
      <div className="ft-slick__dots--custom xl:hidden">
        <div className="loading" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  ;

  return (
    <section className="pt-10 pb-2 ">
      <div className="flex items-center justify-between pb-10 ">
        <div>
          <h1 className="uppercase text-base font-semibold font-primary">
            portfolio
          </h1>
          <h2 className="text-header">projects I am proud of </h2>
        </div>
        <Button className="hidden semi-mid:block">View All Portfolio</Button>
      </div>
      <div className="testimonials-section pt-14 pb-9">
        <Slider {...sliderSettings}>
          <ProjectCard />
          <ProjectCard />
          <ProjectCard />
        </Slider>
      </div>
    </section>
  );
}

export default Portfolio;
