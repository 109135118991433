import React, { useEffect } from "react";
import Layout from "../../layout";
import Banner from "./Banner";
import AboutMe from "../Home/AboutMe";
import DesignProcess from "../Home/DesignProcess";

function BlogPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout mainClass={"pb-10"}>
      <Banner />
      <AboutMe />
      <DesignProcess noUnderline />
    </Layout>
  );
}

export default BlogPage;
