import React from "react";

function ClientCard() {
  return (
    <div className="px-3 mb-5">
      <div className="client-card w-full border border-[1px] border-light-Dark rounded-2xl p-8 ">
        <div>
          <div className=" flex items-start justify-between  ">
            <div className="flex items-start justify-between flex-col">
              <h2 className="text-lg font-semibold  text-white">
                Samantha Davis
              </h2>
              <p className="text-light text-base">USA, California</p>
            </div>
            <div className="social rounded-full flex items-center gap-1 border border-[1px] border-light-Dark p-2">
              <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
                <i className="fa-solid fa-basketball text-light text-base"></i>
              </div>
              <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
                <i className="fa-brands fa-linkedin text-light text-base"></i>
              </div>
              <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
                <i className="fa-brands fa-medium text-light text-base"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="stars py-3 flex items-center justify-start">
          <i className="fa-solid fa-star text-light text-base text-purple" />
          <i className="fa-solid fa-star text-light text-base text-purple" />
          <i className="fa-solid fa-star text-light text-base text-purple" />
          <i className="fa-solid fa-star text-light text-base text-purple" />
          <i className="fa-solid fa-star text-light text-base text-purple" />
        </div>
        <p className="text-white">
          I was blown away by Damien's ability to capture the essence of our
          wedding day. Hes photographs are our memories.
        </p>
      </div>
    </div>
  );
}

export default ClientCard;
