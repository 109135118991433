import React from "react";

function WorkCard() {
  return (
    <div className="px-3 mb-5">
      <div className="why-card w-full border border-[1px] border-light-Dark rounded-2xl p-8 ">
        <div>
          <div className=" flex items-start justify-between  ">
            <div className="social rounded-full flex items-center gap-1 border border-[2px] border-light-Dark bg-dark p-2">
              <span className="rounded-full flex text-2xl text-white w-16 h-16 items-center justify-center font-semibold gap-1 border border-[1px] border-[#2F2F37] bg-light-Dark p-2">
                01
              </span>
            </div>
          </div>
        </div>
        <div className="stars py-3 flex items-start justify-start flex-col">
          <h2 className="text-xl font-semibold  text-white">Detail Oriented</h2>
          <p className="text-white mt-3">
            My design philosophy revolves around understanding users' needs,
            ensuring that every project is tailored for an exceptional user
            experience.
          </p>
        </div>
      </div>
    </div>
  );
}

export default WorkCard;
