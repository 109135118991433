import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as Illustrator } from "../../../assets/svg/Adobe Illustrator.svg";
import { ReactComponent as Photoshop } from "../../../assets/svg/Adobe Photoshop.svg";
import { ReactComponent as XD } from "../../../assets/svg/Adobe XD.svg";
import { ReactComponent as BalsamiqWireframes } from "../../../assets/svg/Balsamiq Wireframes.svg";
import { ReactComponent as Dribbble } from "../../../assets/svg/Dribbble.svg";
import { ReactComponent as Figma } from "../../../assets/svg/figma.svg";
import { ReactComponent as Jira } from "../../../assets/svg/Jira.svg";
import { ReactComponent as Medium } from "../../../assets/svg/Medium.svg";
import { ReactComponent as Notion } from "../../../assets/svg/Notion.svg";
import { ReactComponent as Slack } from "../../../assets/svg/Slack.svg";

function TechStack() {
  const stacks = [
    <Figma className="w-20 h-20 mx-2" />,
    <Illustrator className="w-20 h-20 mx-2" />,
    <Photoshop className="w-20 h-20 mx-2" />,
    <XD className="w-20 h-20 mx-2" />,
    <BalsamiqWireframes className="w-20 h-20 mx-2" />,
    <Jira className="w-20 h-20 mx-2" />,
    <Medium className="w-20 h-20 mx-2" />,
    <Notion className="w-20 h-20 mx-2" />,
    <Dribbble className="w-20 h-20 mx-2" />,
    <Slack className="w-20 h-20 mx-2" />,
  ];

  const sliderSettings = {
    slidesToShow: 9,
    arrows: false,
    slidesToScroll: 1,
    speed: 1000,
    touchMove: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="py-11">
      <Slider {...sliderSettings}>
        {stacks.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Slider>
    </section>
  );
}

export default TechStack;
