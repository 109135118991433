import React from "react";
import { ReactComponent as Star } from "../../assets/svg/Star.svg";
function Marque() {
  const sliderText = [
    "User Research",
    "Product designer",
    "UI Prototyping ",
    "Interaction Designer",
    "Experience Designer",
    "Branding",
    "Usability Testing",
    "Visual Designer",
  ];
  return (
    <div className="bg-blue-50 rounded overflow-hidden scrolling-text-container">
      <div
        className="flex whitespace-nowrap font-semibold text-lg py-2 scrolling-text-inner"
        // @ts-ignore
        style={{ "--marquee-speed": "20s", "--direction": "scroll-left" }}
        role="marquee"
      >
        <div className="flex scrolling-text">
          {sliderText.map((item, index) => (
            <div
              key={index}
              className="px-1 scrolling-text-item flex items-center justify-center flex items-center justify-center gap-2"
            >
              <span
                className="text-[#D6D0FB] leading-[27px] text-[18px]"
                style={{ whiteSpace: "nowrap" }}
              >
                {item}
              </span>
              <Star className="w-5 h-5 self-center" />
            </div>
          ))}
        </div>
        <div className="flex scrolling-text">
          {sliderText.map((item, index) => (
            <div
              key={index}
              className="px-1 scrolling-text-item flex items-center justify-center flex items-center justify-center gap-2"
            >
              <span
                className="text-[#D6D0FB] leading-[27px] text-[18px]"
                style={{ whiteSpace: "nowrap" }}
              >
                {item}
              </span>
              <Star className="w-5 h-5 self-center" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Marque;
