import React, { useState } from "react";
import { ReactComponent as Arrow } from "../../assets/svg/arrow.svg";
import CircularBtn from "../CircularBtn";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../Button";
import Marque from "./marque";

function Footer() {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredTop, setIsHoveredTop] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };
  const handleMouseOverTop = () => {
    setIsHoveredTop(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  const handleMouseOutTop = () => {
    setIsHoveredTop(false);
  };

  const sliderSettings = {
    slidesToShow: 6,
    arrows: false,
    slidesToScroll: 1,
    speed: 2000,
    touchMove: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="border-none">
      <div className="flex items-center justify-center">
        <div
          className="font-bold font-primary uppercase text-5xl sm:text-[5.7rem] mid:text-[7.5rem] lg:text-[9.5rem] gap-6 xl:text-[11rem] 2xl:text-[12.5rem] flex flex-col justify-center xl:justify-start mb-10"
          style={{
            color: isHovered ? "#cccccc" : "#232329",
            transition: "color 0.3s ease-in-out",
          }}
        >
          <span style={{ whiteSpace: "nowrap" }}>let's work</span>
          <span className="flex items-center gap-5 w-full">
            together
            <CircularBtn
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              className="custom-btn-css sm:mt-4 semi-mid:mt-8 lg:mt-14 xl:mt-24"
            >
              <Arrow className="arrow" />
            </CircularBtn>
          </span>
        </div>
      </div>
      <div className="my-5 sm:my-7 mid:my-10 lg:my-20  ">
        <Marque />
      </div>

      <div className="flex items-center justify-between flex-col semi-mid:flex-row gap-6 mb-20">
        <div className="text-2xl z-40 semi-mid:text-xl mid:text-2xl lg:text-3xl text-white text-primary uppercase font-primary flex items-center gap-1">
          Nikita
          <span className="w-1.5 mid:w-2 h-1.5 mid:h-2 rounded bg-purple content-none mt-2 mid:mt-3 lg:mt-4"></span>
        </div>
        <div className="flex items-center justify-center flex-col gap-6">
          <Button className="flex items-center justify-center gap-2">
            Download Cv
            <i className="fa-solid fa-file-invoice"></i>
          </Button>
          <div className="social rounded-full flex items-center gap-1 border border-[1px] border-light-Dark p-2">
            <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
              <i className="fa-solid fa-basketball text-white text-base"></i>
            </div>
            <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
              <i className="fa-brands fa-linkedin text-white text-base"></i>
            </div>
            <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
              <i className="fa-brands fa-medium text-white text-base"></i>
            </div>
          </div>
        </div>
        <div
          className="flex items-center justify-center semi-mid:self-end semi-mid:pr-5  gap-2 cursor-pointer"
          onClick={() => window.scrollTo(0, 0)}
          onMouseOver={handleMouseOverTop}
          onMouseOut={handleMouseOutTop}
        >
          <h1
            style={{
              color: isHoveredTop ? "#cccccc" : "#D6D0FB",
              transition: "color 0.3s ease-in-out",
            }}
          >
            Scroll Top
          </h1>
          <i
            className="fa-solid fa-arrow-up text-purple text-xl "
            style={{
              transform: isHoveredTop ? "translateY(-4px)" : "translateY(0)",
              transition: "transform 0.1s ease-in-out",
            }}
          ></i>
        </div>
      </div>
    </section>
  );
}

export default Footer;
