export const country = [
  { label: "Afghanistan (AF)" },
  { label: "Albania (AL)" },
  { label: "Algeria (DZ)" },
  { label: "Andorra (AD)" },
  { label: "Angola (AO)" },
  { label: "Antigua and Barbuda (AG)" },
  { label: "Argentina (AR)" },
  { label: "Armenia (AM)" },
  { label: "Australia (AU)" },
  { label: "Austria (AT)" },
  { label: "Azerbaijan (AZ)" },
  { label: "Bahamas (BS)" },
  { label: "Bahrain (BH)" },
  { label: "Bangladesh (BD)" },
  { label: "Barbados (BB)" },
  { label: "Belarus (BY)" },
  { label: "Belgium (BE)" },
  { label: "Belize (BZ)" },
  { label: "Benin (BJ)" },
  { label: "Bhutan (BT)" },
  { label: "Bolivia (BO)" },
  { label: "Bosnia and Herzegovina (BA)" },
  { label: "Botswana (BW)" },
  { label: "Brazil (BR)" },
  { label: "Brunei (BN)" },
  { label: "Bulgaria (BG)" },
  { label: "Burkina Faso (BF)" },
  { label: "Burundi (BI)" },
  { label: "Cabo Verde (CV)" },
  { label: "Cambodia (KH)" },
  { label: "Cameroon (CM)" },
  { label: "Canada (CA)" },
  { label: "Central African Republic (CF)" },
  { label: "Chad (TD)" },
  { label: "Chile (CL)" },
  { label: "China (CN)" },
  { label: "Colombia (CO)" },
  { label: "Comoros (KM)" },
  { label: "Congo (CG)" },
  { label: "Costa Rica (CR)" },
  { label: "Croatia (HR)" },
  { label: "Cuba (CU)" },
  { label: "Cyprus (CY)" },
  { label: "Czech Republic (CZ)" },
  { label: "Denmark (DK)" },
  { label: "Djibouti (DJ)" },
  { label: "Dominica (DM)" },
  { label: "Dominican Republic (DO)" },
  { label: "Ecuador (EC)" },
  { label: "Egypt (EG)" },
  { label: "El Salvador (SV)" },
  { label: "Equatorial Guinea (GQ)" },
  { label: "Eritrea (ER)" },
  { label: "Estonia (EE)" },
  { label: "Eswatini (SZ)" },
  { label: "Ethiopia (ET)" },
  { label: "Fiji (FJ)" },
  { label: "Finland (FI)" },
  { label: "France (FR)" },
  { label: "Gabon (GA)" },
  { label: "Gambia (GM)" },
  { label: "Georgia (GE)" },
  { label: "Germany (DE)" },
  { label: "Ghana (GH)" },
  { label: "Greece (GR)" },
  { label: "Grenada (GD)" },
  { label: "Guatemala (GT)" },
  { label: "Guinea (GN)" },
  { label: "Guinea-Bissau (GW)" },
  { label: "Guyana (GY)" },
  { label: "Haiti (HT)" },
  { label: "Honduras (HN)" },
  { label: "Hungary (HU)" },
  { label: "Iceland (IS)" },
  { label: "India (IN)" },
  { label: "Indonesia (ID)" },
  { label: "Iran (IR)" },
  { label: "Iraq (IQ)" },
  { label: "Ireland (IE)" },
  { label: "Israel (IL)" },
  { label: "Italy (IT)" },
  { label: "Jamaica (JM)" },
  { label: "Japan (JP)" },
  { label: "Jordan (JO)" },
  { label: "Kazakhstan (KZ)" },
  { label: "Kenya (KE)" },
  { label: "Kiribati (KI)" },
  { label: "Kuwait (KW)" },
  { label: "Kyrgyzstan (KG)" },
  { label: "Laos (LA)" },
  { label: "Latvia (LV)" },
  { label: "Lebanon (LB)" },
  { label: "Lesotho (LS)" },
  { label: "Liberia (LR)" },
  { label: "Libya (LY)" },
  { label: "Liechtenstein (LI)" },
  { label: "Lithuania (LT)" },
  { label: "Luxembourg (LU)" },
  { label: "Madagascar (MG)" },
  { label: "Malawi (MW)" },
  { label: "Malaysia (MY)" },
  { label: "Maldives (MV)" },
  { label: "Mali (ML)" },
  { label: "Malta (MT)" },
  { label: "Marshall Islands (MH)" },
  { label: "Mauritania (MR)" },
  { label: "Mauritius (MU)" },
  { label: "Mexico (MX)" },
  { label: "Micronesia (FM)" },
  { label: "Moldova (MD)" },
  { label: "Monaco (MC)" },
  { label: "Mongolia (MN)" },
  { label: "Montenegro (ME)" },
  { label: "Morocco (MA)" },
  { label: "Mozambique (MZ)" },
  { label: "Myanmar (MM)" },
  { label: "Namibia (NA)" },
  { label: "Nauru (NR)" },
  { label: "Nepal (NP)" },
  { label: "Netherlands (NL)" },
  { label: "New Zealand (NZ)" },
  { label: "Nicaragua (NI)" },
  { label: "Niger (NE)" },
  { label: "Nigeria (NG)" },
  { label: "North Korea (KP)" },
  { label: "North Macedonia (MK)" },
  { label: "Norway (NO)" },
  { label: "Oman (OM)" },
  { label: "Pakistan (PK)" },
  { label: "Palau (PW)" },
  { label: "Palestine (PS)" },
  { label: "Panama (PA)" },
  { label: "Papua New Guinea (PG)" },
  { label: "Paraguay (PY)" },
  { label: "Peru (PE)" },
  { label: "Philippines (PH)" },
  { label: "Poland (PL)" },
  { label: "Portugal (PT)" },
  { label: "Qatar (QA)" },
  { label: "Romania (RO)" },
  { label: "Russia (RU)" },
  { label: "Rwanda (RW)" },
  { label: "Saint Kitts and Nevis (KN)" },
  { label: "Saint Lucia (LC)" },
  { label: "Saint Vincent and the Grenadines (VC)" },
  { label: "Samoa (WS)" },
  { label: "San Marino (SM)" },
  { label: "Sao Tome and Principe (ST)" },
  { label: "Saudi Arabia (SA)" },
  { label: "Senegal (SN)" },
  { label: "Serbia (RS)" },
  { label: "Seychelles (SC)" },
  { label: "Sierra Leone (SL)" },
  { label: "Singapore (SG)" },
  { label: "Slovakia (SK)" },
  { label: "Slovenia (SI)" },
  { label: "Solomon Islands (SB)" },
  { label: "Somalia (SO)" },
  { label: "South Africa (ZA)" },
  { label: "South Korea (KR)" },
  { label: "South Sudan (SS)" },
  { label: "Spain (ES)" },
  { label: "Sri Lanka (LK)" },
  { label: "Sudan (SD)" },
  { label: "Suriname (SR)" },
  { label: "Sweden (SE)" },
  { label: "Switzerland (CH)" },
  { label: "Syria (SY)" },
  { label: "Taiwan (TW)" },
  { label: "Tajikistan (TJ)" },
  { label: "Tanzania (TZ)" },
  { label: "Thailand (TH)" },
  { label: "Timor-Leste (TL)" },
  { label: "Togo (TG)" },
  { label: "Tonga (TO)" },
  { label: "Trinidad and Tobago (TT)" },
  { label: "Tunisia (TN)" },
  { label: "Turkey (TR)" },
  { label: "Turkmenistan (TM)" },
  { label: "Tuvalu (TV)" },
  { label: "Uganda (UG)" },
  { label: "Ukraine (UA)" },
  { label: "United Arab Emirates (AE)" },
  { label: "United Kingdom (GB)" },
  { label: "United States (US)" },
  { label: "Uruguay (UY)" },
  { label: "Uzbekistan (UZ)" },
  { label: "Vanuatu (VU)" },
  { label: "Vatican City (VA)" },
  { label: "Venezuela (VE)" },
  { label: "Vietnam (VN)" },
  { label: "Yemen (YE)" },
  { label: "Zambia (ZM)" },
  { label: "Zimbabwe (ZW)" },
];
