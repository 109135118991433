import React from "react";
import Image from "../../../../assets/images/nikita2.png";
import ImageCard from "../../../../assets/images/blog.png";
import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
function RightSide() {
  const navigate = useNavigate();
  function MobileCard() {
    return (
      <div className="blog-card flex flex-col   border border-2 p-4 rounded-xl border-light-Dark ">
        <div className="image-container m-h-[350px]  overflow-hidden rounded-2xl">
          <img src={ImageCard} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="text-container mt-8">
          <h1 className="text-white text-xl  font-semibold uppercase font-primary">
            Most efficient design process for budding ui ux designers in 2024
          </h1>
          <p className="mt-2">
            Our event photography service is dedicated to capturing the magic of
            your special occasions. Whether it's a wedding, corporate event, or
            milestone celebration, we're there to document every heartfelt
            moment. We blend into the background, ensuring natural and candid
            shots that reflect the emotions of the day.
          </p>

          <div className="mt-7 mb-6 ">
            <Button
              className="bg-purple border-[1px] border-[#5926AC] hover:bg-[#5926AC] flex items-center justify-center gap-3  "
              type="submit"
              onClick={() => navigate("/DetailWork/134")}
            >
              Read More
              <i className="fa-solid fa-arrow-right " />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-10 pl-6">
      <div className="flex  gap-5">
        <div className="rounded-full w-12 h-12 flex items-center justify-center border border-[3px] border-purple bg-light-Dark overflow-hidden">
          <img src={Image} alt="" className="w-full h-full object-cover" />
        </div>
        <div>
          <h1>Nikita Chaudhary</h1>
          <h1>Author | UI UX DESIGNER</h1>
        </div>
      </div>
      <div className="flex items-center justify-start gap-5">
        <div className=" w-fit rounded-full flex flex-col items-center gap-3 border border-[1px] border-light-Dark p-2">
          <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
            <i className="fa-solid fa-basketball text-light text-base"></i>
          </div>
          <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
            <i className="fa-brands fa-linkedin text-light text-base"></i>
          </div>
          <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
            <i className="fa-brands fa-medium text-light text-base"></i>
          </div>
        </div>
        <div className="flex flex-col justify-center items-start gap-3 mt-3">
          <h1 className="w-10 h-10 text-white text-base ">Medium</h1>
          <h1 className="w-10 h-10 text-white text-base">Linkedin</h1>
          <h1 className="w-10 h-10 text-white text-base">Dribbble</h1>
        </div>
      </div>
      <MobileCard />
    </div>
  );
}

export default RightSide;
