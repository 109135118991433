import React, { useEffect } from "react";
import Layout from "../../layout";
import ImageHolder from "./BlogDetailsComponents/ImageHolder";
import DetailHolder from "./BlogDetailsComponents/DetailHolder/Index";

function BlogDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <ImageHolder />
      <DetailHolder />
    </Layout>
  );
}

export default BlogDetail;
