import React from "react";
import Nikita from "../../../assets/images/nikita2.png";
import { ReactComponent as Star } from "../../../assets/svg/Star.svg";
import Button from "../../../components/Button";

function AboutMe() {
  function AboutTitle() {
    return (
      <section className="py-10">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="uppercase text-base font-semibold font-primary">
              About
            </h1>
            <h2 className="text-header text-light">I am Nikita</h2>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <AboutTitle />
      <section className="py-14 flex  gap-5 flex-col mid:flex-row h-full">
        <article className="image-holder w-full mid:w-1/2 rounded-2xl flex-shrink-0 min-h-full md:min-h-[480px] semi-mid:min-h-[500px] ">
          <img
            src={Nikita}
            alt="Nikita"
            className="w-full h-full object-cover object-top rounded-2xl"
          />
        </article>
        <article className="data-holder w-full mid:w-1/2 self-center border border-[1px] border-light-Dark rounded-2xl flex flex-col justify-between min-h-full">
          <div className="intro p-6 border-b-[1px] border-light-Dark">
            <h1 className="flex items-center justify-start font-primary text-2xl font-medium">
              <Star className="w-5 h-5 px-1 lg:w-8 lg:h-8 self-center" />
              <span>&nbsp;Introduction&nbsp;</span>
            </h1>
            <p className="mt-4 font-primary text-base">
              My journey as a photographer has been a lifelong quest to capture
              the extraordinary in the ordinary, to freeze fleeting moments in
              time, and to share the world's beauty as I see it. Based in the
              enchanting landscapes of the USA, I find inspiration in every
              corner of this diverse and vibrant country. Join me as we embark
              on a visual odyssey, where each photograph tells a story, and
              every frame is a piece of my heart.
            </p>
          </div>
          <div className="contact p-6">
            <h1 className="flex items-center justify-start font-primary text-2xl font-medium">
              <Star className="w-5 h-5 px-1 lg:w-8 lg:h-8 self-center" />
              <span>&nbsp;Contact Information&nbsp;</span>
            </h1>
            <div className="flex flex-col md:flex-row items-start flex-wrap gap-3.5 md:items-center justify-between my-6">
              <div>
                <h1 className="font-primary font-medium">Email</h1>
                <h2>nikitachaudhary821@gmail.com</h2>
              </div>
              <div>
                <h1 className="font-primary font-medium">Phone Number</h1>
                <h2>+977 9808008216</h2>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-start flex-wrap gap-3.5 md:items-center justify-between">
              <div className="social rounded-full flex items-center gap-3 border border-[1px] border-light-Dark p-2">
                <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
                  <i className="fa-solid fa-basketball text-light text-base"></i>
                </div>
                <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
                  <i className="fa-brands fa-linkedin text-light text-base"></i>
                </div>
                <div className="rounded-full w-10 h-10 flex items-center justify-center border border-[1px] border-[#2F2F37] bg-light-Dark">
                  <i className="fa-brands fa-medium text-light text-base"></i>
                </div>
              </div>
              <div className="btn flex items-center justify-center gap-3">
                <Button underLine>Let’s Work</Button>
                <Button className="flex items-center justify-center gap-2">
                  Download Cv
                  <i className="fa-solid fa-file-invoice"></i>
                </Button>
              </div>
            </div>
          </div>
        </article>
      </section>
    </>
  );
}

export default AboutMe;
