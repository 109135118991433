import React, { useState } from "react";
import CircularBtn from "../../../components/CircularBtn";
import Image from "../../../assets/images/shadow (1).png";
import { ReactComponent as Arrow } from "../../../assets/svg/arrow.svg";
import { useNavigate } from "react-router-dom";
function Banner() {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  return (
    <section className="py-10">
      <CircularBtn onClick={() => navigate("/")}>
        <Arrow className="arrow-straight" />
      </CircularBtn>
      <div className=" px-1">
        <h1 className="uppercase my-5 text-3xl text-white font-medium break-words ">
          IT Company website
        </h1>
        <p className="text-base text-white leading-[24px] mt-2">
          Our event photography service is dedicated to capturing the magic of
          your special occasions. Whether it's a wedding, corporate event, or
          milestone celebration, we're there to document every heartfelt moment.
          We blend into the background, ensuring natural and candid shots that
          reflect the emotions of the day.
        </p>
        <div
          className="design-card-image-holder my-10 md:my-14 mb-5 bg-light-Dark py-20 border-[1px] border-[#2F2F37]  px-8 flex items-center justify-center rounded-lg"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <img
            src={Image}
            alt=""
            className="design-card-image"
            style={{
              filter: "drop-shadow(0px 0px 4px #525252)",
              transform: isHovered ? "scale(1.1)" : "scale(1)",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default Banner;
