import React from "react";
import Laptop from "../../assets/images/laptop.png";
import { ReactComponent as Arrow } from "../../assets/svg/arrow.svg";
function ProjectCard() {
  return (
    <div className="px-3 mb-5 rounded-xl">
      <img src={Laptop} alt=" " className="rounded-xl" />
      <div className="mt-5 flex items-center justify-between">
        <div className="flex items-start justify-between flex-col">
          <h2 className="text-lg font-semibold  text-white">Website Builder</h2>
          <p className="text-light text-base">January 2023</p>
        </div>
        <a className="cursor-pointer uppercase flex items-center font-primary text-white hover:text-light font-medium text-base gap-2 bg-dark pb-2  border-b-[2px] border-light-Dark">
          view Project
          <Arrow className="arrow w-4 h-4" />
        </a>
      </div>
    </div>
  );
}

export default ProjectCard;
